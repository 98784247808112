<script setup lang="ts">
import { ProductProps } from "./Product.props"
import {
  optionsMostSold,
  mdOptionsCardsWithCTA,
  smOptionsCardsWithCTA
} from "./Product.carouselOptions"
import { sm } from "~/utils/breakpoints"
import { VerticalProps } from "~/components/ProductTile/Overall/Vertical.props"

const props = withDefaults(defineProps<ProductProps>(), {
  backgroundColor: "white"
})

const currentOptions = computed(() => {
  if (props.typeCard === "MostSold") {
    return optionsMostSold
  } else {
    return sm.value ? smOptionsCardsWithCTA : mdOptionsCardsWithCTA
  }
})
</script>

<template>
  <div
    class="px-4 pb-7 pt-9 md:pb-9"
    :class="{
      'bg-white': backgroundColor === 'white',
      'bg-tiffany-10': backgroundColor === 'tiffany',
      'bg-grey-main': backgroundColor === 'grey'
    }"
  >
    <div class="relative">
      <div
        class="
          padded
          mb-4
          flex flex-wrap
          items-center
          justify-between
          gap-4
          md:mb-8
          md:flex-nowrap
          md:gap-8
        "
        :class="[
          {
            ' flex-nowrap md:justify-normal': typeCard === 'MostSold'
          }
        ]"
      >
        <h3 v-if="title" class="dolphin-bold md:elephant-bold">
          {{ title }}
        </h3>
        <UtilsButton
          v-if="showAllProducts"
          class="custom-btn-pig-medium hidden w-fit cursor-pointer md:block"
          v-bind="showAllProducts"
          order="last"
        >
        </UtilsButton>
      </div>

      <div class="padded mb-6 md:mb-8">
        <UtilsMarkdown v-if="text" :content="text" class="pig text-black-80" />
      </div>

      <div
        v-if="suggestedCards?.length"
        class="carousel-container-overflowed padded"
      >
        <GAListCarouselProductRails :name="title">
          <GAItem
            :id="`suggested-product-${$route.params.product || typeCard}`"
            :item="
              (typeCard === 'MostSold'
                ? suggestedCards.slice(0, 6)
                : suggestedCards ) as VerticalProps[]
            "
          >
            <GAViewItemList>
              <AppCarousel
                class="carousel-overflowed select-none"
                :items="
                  typeCard === 'MostSold'
                    ? suggestedCards.slice(0, 6)
                    : suggestedCards
                "
                :swiper-props="currentOptions"
                :theme="currentOptions?.theme"
                :key="sm ? 1 : 0"
              >
                <template #default="{ item }">
                  <template v-if="!item.cardType">
                    <ProductTileOverallListingMostSold
                      class="swiper-tile-6"
                      :key="item.productCode"
                      v-if="typeCard === 'MostSold'"
                      v-bind="item"
                    />
                    <ProductTileOverallVertical
                      v-else
                      class="swiper-tile-4"
                      v-bind="item"
                    />
                  </template>
                </template>
              </AppCarousel>
            </GAViewItemList>
          </GAItem>
        </GAListCarouselProductRails>
      </div>

      <div class="px-4 pt-4 md:hidden">
        <UtilsButton
          v-if="showAllProducts"
          class="custom-btn-pig-medium w-full cursor-pointer"
          v-bind="showAllProducts"
          order="last"
          theme="outlined-green"
        >
        </UtilsButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

:deep(.swiper-slide:first-child > div) {
  margin-left: 0;
}

:deep(.swiper-slide:last-child > div) {
  margin-right: 0;
}
.swiper-tile-6 {
  margin: 0 8px;
}
.swiper-tile-4 {
  margin: 0 8px;
}

@screen md {
  .swiper-tile-6 {
    margin: 0 12px;
    width: calc(((100vw - 142px) / 6) - 24px);
  }
  .swiper-tile-4 {
    margin: 0 12px;
    width: calc(((100vw - 142px) / 4) - 24px);
  }
}

@screen lg {
  .swiper-tile-6 {
    width: calc(((100vw - 456px) / 6) - 24px);
  }
  .swiper-tile-4 {
    width: calc(((100vw - 456px) / 4) - 24px);
  }
}

</style>
